import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import moment from 'moment'
import he from 'he'
import Slider from 'react-slick'

import SEO from '../components/seo'
import PropertyStats from '../components/property-stats'
import TeamMember from '../components/team-member'
import Map from '../components/map'
import PropertySearch from '../components/property-search'
import SocialLinks from '../components/social-links'
import RelatedProperties from '../components/related-properties'
import Video from '../components/video'

class BlockUpdate extends Component {
  shouldComponentUpdate() {
    return false
  }
  render() {
    return (<>{ this.props.children }</>)
  }
}

class Property extends Component {

  state = {
    mobile: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  }

  onScroll = () => {
    if (!this.ticking) {
      window.requestAnimationFrame(this.scrollDetect)
      this.ticking = true
    }
  }

  onResize = () => {
    this.setState({ mobile: (window.innerWidth < 900) })
  }

  scrollDetect = () => {
    let $cont = document.querySelector('.property__split')
    let $info = document.querySelector('.property__info')

    let infoHeight = Array.from($info.children).reduce((acc, el) => el.scrollHeight + acc, 0)

    let scrollPercent = window.scrollY / ($cont.clientHeight - window.innerHeight)

    if (scrollPercent < 0) scrollPercent = 0
    if (scrollPercent > 1) scrollPercent = 1


    scrollPercent = Math.round(scrollPercent * 10000) / 10000
    scrollPercent = Math.min(scrollPercent, 1)

    if (window.innerWidth >= 900)
      $info.style.transform = `translate3d(0, ${(scrollPercent * ($cont.scrollHeight - infoHeight + 20))}px, 0)`
    else
      $info.style.transform = null

    this.ticking = false
  }

  getAgent(agent) {
    let { team } = this.props.data
    let member = team.edges.find(el => el.node.acf.rex_data.email === agent.email_address)
    if (!member) return agent
    return member.node.acf.rex_data
  }

  renderVideo(url) {
    let id = url.split('=')[1]
    return (<Video video={id} autoPlay={false} />)
  }

  render() {

    let { property, related } = this.props.data
    let { rex_data } = property.acf
    let { menu_items } = this.props.data.wordpressAcfOptions.options

    let inspections = []
    let auction = []

    rex_data.events.forEach((el, i) => {
      if (el.event_type === 'auction')
        auction.push(el)
      else
        inspections.push(el)
    })

    let pictures = rex_data.images.map((image, i) => (<img src={image.url} alt='' key={i} />))
    let gallery = (<div className='property__images'>{ pictures }</div>)
    rex_data.links.forEach((el) => {
      if (el.link_url.indexOf('youtube') !== -1) pictures.splice(1,0,this.renderVideo(el.link_url))
    })

    let { price_advertise_as } = rex_data
    if (price_advertise_as === 'Set Sale') {
      auction = []
      price_advertise_as = 'Deadline Sale'
    }

    if (this.state.mobile) {
      const settings = {
        slidesToShow: 1,
        arrows: false,
        cssEase: 'ease',
      }
      gallery = (<Slider {...settings} className='property__images'>{ pictures }</Slider>)
    }

    let dayDiff = date => moment(date).diff(new Date(), "days")

    return (
      <>
        <SEO title={he.decode(property.yoast_meta.yoast_wpseo_title)} description={property.yoast_meta.yoast_wpseo_metadesc} />
        <div className='property__split'>
          { gallery }
          <div className='property__info__wrap'>
            <div className='property__info'>
              <div className='property__content'>
                <h5>More —</h5>
                <h2>{ rex_data.advert_internet.heading }</h2>
                <h1>{ rex_data.address.formats.street_name_number_w_suburb }</h1>
                { rex_data.system_listing_state === 'current' &&
                  <div>
                    <span className='property__price'>{ price_advertise_as }</span>
                  </div>
                }
                <PropertyStats {...rex_data.attributes} />

                { rex_data.system_listing_state === 'sold' &&
                  <div>
                    <span className='property__price property__price--sold'>Sold</span>
                  </div>
                }

                { auction.length > 0 && dayDiff(auction[0].event_date) >= 0 && price_advertise_as !== 'Tender' &&
                  <div className='property__inspections'>
                    <div>Auction</div>
                    <ul>
                      <li>
                        <span>— { moment(auction[0].event_date).format('dddd D MMM YYYY') }, </span>
                        <span>{ moment(`1970-01-01 ${auction[0].event_time_start}`).format('h:mma') }</span>
                      </li>
                    </ul>
                  </div>
                }

                { inspections.length > 0 &&
                  <div className='property__inspections'>
                    <div>Inspections</div>
                    <ul>
                      { inspections.map((el, i) => {
                        if (dayDiff(el.event_date) < 0) return null
                        return (
                          <li key={i}>
                            <span>— { moment(el.event_date).format('dddd D MMM YYYY') }, </span>
                            <span>{ moment(`1970-01-01 ${el.event_time_start}`).format('h:mma') }-{ moment(`1970-01-01 ${el.event_time_end}`).format('h:mma') }</span>
                          </li>
                        )
                      }) }
                    </ul>
                  </div>
                }
              </div>
              <div className='property__content' style={{background: '#fff'}}>
                { rex_data.advert_internet.body.split('\n').map((line, i) => (<p key={i}>{ line }</p>)) }
              </div>
              <div className='property__content'>
                { rex_data.listing_agent_1 && <TeamMember rex_data={this.getAgent(rex_data.listing_agent_1)} link={true} /> }
                { rex_data.listing_agent_2 && <TeamMember rex_data={this.getAgent(rex_data.listing_agent_2)} link={true} /> }
                <Link to='/about' className='btn'>More — About Us</Link>
              </div>
              <BlockUpdate>
                <Map lat={rex_data.address.latitude} lng={rex_data.address.longitude} />
              </BlockUpdate>
            </div>
          </div>
        </div>
        <div className='property__documents'>
          <h3>More — Documents</h3>
          <ul>
            { menu_items.map((el, i) => (<li key={i}><Link to={el.url}>{ el.text }</Link></li>)) }
          </ul>
        </div>

        <div className='property__social'>
          <PropertySearch />
          <SocialLinks />
        </div>

        { related && rex_data.system_listing_state === 'current' &&
          <div className='property__related'>
            <div className='row'>
              <h3 style={{marginBottom: 0}}>More — Properties for sale</h3>
            </div>

            <RelatedProperties properties={related} />

          </div>
        }
      </>
    )
  }
}

export default Property

export const propertyQuery = graphql`
  query ($id: String!) {
    property: wordpressWpProperty(id: {eq: $id}) {
      id
      slug
      title
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        rex_data {
          price_advertise_as
          price_match_sale
          attributes {
            bedrooms
            bathrooms
            total_car_accom
            landarea_m2
          }
          address {
            latitude
            longitude
            suburb_or_town
            formats {
              street_name_number_w_suburb
              full_address
            }
          }
          advert_internet {
            heading
            body
          }

          images {
            url
          }
          location {
            location_name
          }
          links {
            link_type
            link_url
          }
          listing_agent_1 {
            name
            email_address
          }
          listing_agent_2 {
            name
            email_address
          }
          system_listing_state

          events {
            event_type
            event_type_display
            event_date
            event_time_start
            event_time_end
          }

        }
      }
    }
    wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
      options {
        menu_items {
          text
          url
        }
      }
    }
    related: allWordpressWpProperty(filter:{id:{ne:$id}, acf:{rex_data:{system_listing_state:{eq:"current"}}}}, limit: 3) {
      edges {
        node {
          title
          slug
          acf {
            rex_data {
              attributes {
                bedrooms
                bathrooms
                total_car_accom
              }
              images {
                url
              }
              address {
                formats {
                  street_name_number_w_suburb
                }
              }
            }
          }
        }
      }
    }
    team: allWordpressWpTeam {
      edges {
        node {
          title
          acf {
            rex_data {
              first_name
              last_name
              email
              settings {
                phone_direct
                phone_mobile
                email_signature
                position
              }
            }
          }
        }
      }
    }
  }
`
