import React, { Component } from 'react'
import axios from 'axios'

import Plyr from '../components/plyr'

class Video extends Component {

  render() {

    return (
      <>
        <Plyr
          type='youtube'
          ref='player'
          videoId={ this.props.video }
          autoplay={ this.props.autoPlay }
          urls={{
            youtube: {
              api: 'https://noembed.com/embed?url={0}'
            }
          }}
        />
      </>
    )
  }

}

export default Video
